// eslint-disable-next-line import/named
import request, { downLoadService } from '@/utils/request'

export function getSaleReport(params) {
  return request.get('/api/v1/backend/reports/sales', { params })
}

export function downloadShippedFile(data) {
  return downLoadService.post('/api/v1/backend/reports/tobeshipped/export', data)
}

export function downloadSalesFile(data) {
  return downLoadService.post('/api/v1/backend/reports/sales/export', data)
}
