<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-card
      v-permission="['110001', '110002']"
      class="mb-4"
    >
      <v-card-title class="align-start">
        <span class="font-weight-semibold">报表下载</span>
        <v-spacer></v-spacer>
      </v-card-title>

      <v-card-subtitle class="mb-8 mt-n5">
        <span>点击图标下载对应报表</span>
      </v-card-subtitle>

      <v-card-text>
        <v-row>
          <v-col
            v-permission="['110001']"
            cols="6"
            md="3"
            class="d-flex align-center"
          >
            <v-btn
              icon
              x-large
              @click="toBeShippedDownload"
            >
              <v-avatar
                size="44"
                color="primary"
                rounded
                class="elevation-1"
              >
                <v-icon
                  dark
                  color="white"
                  size="30"
                >
                  {{ icons.mdiTruckCargoContainer }}
                </v-icon>
              </v-avatar>
            </v-btn>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Shipped
              </p>
              <h3 class="text-xl font-weight-semibold">
                待发货统计
              </h3>
            </div>
          </v-col>
          <v-col
            v-permission="['110002']"
            cols="6"
            md="3"
            class="d-flex align-center"
          >
            <v-btn
              icon
              x-large
              @click="salesDownload"
            >
              <v-avatar
                size="44"
                color="success"
                rounded
                class="elevation-1"
              >
                <v-icon
                  dark
                  color="white"
                  size="30"
                >
                  {{ icons.mdiChartTimeline }}
                </v-icon>
              </v-avatar>
            </v-btn>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Sales
              </p>
              <h3 class="text-xl font-weight-semibold">
                商户销售报表
              </h3>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title class="align-start">
        <span class="font-weight-semibold">销售报表</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-row
        class="px-2 ma-0"
      >
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="options.order_no"
            dense
            outlined
            clearable
            label="订单号"
            placeholder="输入完整订单号"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-menu
            transition="scroll-y-transition"
            :close-on-content-click="dates.length === 2"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="dateRangeText"
                label="时间段"
                :append-icon="icons.mdiCalendar"
                readonly
                outlined
                dense
                clearable
                v-bind="attrs"
                v-on="on"
                @click:clear="clearDates"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              locale="zh-cn"
              range
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-btn
            color="primary"
            outlined
            @click="getDesserts"
          >
            <v-icon>{{ icons.mdiFilterMultipleOutline }}</v-icon>&nbsp;
            FILTER
          </v-btn>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row
        class="px-2 ma-0"
      >
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="options.search"
            dense
            outlined
            clearable
            hide-details
            label="Search"
            placeholder="Search by note name"
            :append-icon="icons.mdiMagnify"
            @click:append="getDesserts"
          />
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :items-per-page.sync="options.pageSize"
        :page.sync="options.page"
        :server-items-length="totalDesserts"
        :loading="loading"
        :disable-sort="true"
        :footer-props="{itemsPerPageOptions: [10, 15, 20, 50, 100]}"
        @update:items-per-page="getDesserts"
        @update:page="getDesserts"
      >
        <template #item.total="{ item }">
          {{ item.qty * item.price | amountFormat }}
        </template>
        <template #item.created_at="{ item }">
          {{ item.created_at | dateFormat }}
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="salesDownloadDialog"
      max-width="850"
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card
        class="pa-sm-10"
      >
        <v-card-title class="justify-center text-h5">
          导出商户销售报表
        </v-card-title>
        <v-card-text>
          <v-menu
            transition="scroll-y-transition"
            :close-on-content-click="exportDates.length === 2"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="exportDateRangeText"
                label="时间段"
                :append-icon="icons.mdiCalendar"
                readonly
                outlined
                dense
                clearable
                v-bind="attrs"
                v-on="on"
                @click:clear="exportClearDates"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="exportDates"
              locale="zh-cn"
              range
            ></v-date-picker>
          </v-menu>
        </v-card-text>
        <v-card-text>
          <v-text-field
            v-model="merchantSearch"
            dense
            outlined
            hide-details
            label="搜索"
            placeholder="搜索商户名"
            :append-icon="icons.mdiMagnify"
            class="flex-grow-0"
            @click:append="getMerchants"
          />
          <v-data-table
            v-model="defaultSelectMerchant"
            :headers="merchantHeaders"
            :items="merchants"
            :items-per-page.sync="merchantPageSize"
            :page.sync="merchantPage"
            :server-items-length="totalMerchants"
            :loading="merchantLoading"
            :footer-props="{itemsPerPageOptions: [10, 15, 20, 50]}"
            item-key="id"
            disable-sort
            show-select
            @update:items-per-page="getMerchants"
            @update:page="getMerchants"
          >
          </v-data-table>
        </v-card-text>
        <v-card-actions class="justify-center mt-3">
          <v-btn
            color="primary"
            dark
            @click="exportSalesSubmit"
          >
            下载
          </v-btn>
          <v-btn
            outlined
            @click="cancelExportSalesDialog"
          >
            取消
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  getSaleReport,
  downloadShippedFile,
  downloadSalesFile,
} from '@/api/report'
import {
  mdiMagnify,
  mdiClose,
  mdiDelete,
  mdiArrowRightCircle,
  mdiCalendar,
  mdiPencil,
  mdiTruckCargoContainer,
  mdiChartTimeline,
  mdiFilterMultipleOutline,
} from '@mdi/js'
import { getMerchants } from '@/api/merchant'

export default {
  name: 'Report',
  data() {
    return {
      desserts: [],
      totalDesserts: 0,
      loading: true,
      deleteDialog: false,
      headers: [
        { text: '订单号', value: 'order_no' },
        { text: '商户', value: 'merchant_name' },
        { text: '产品名称', value: 'goods_name' },
        { text: '产品规格', value: 'option_value' },
        { text: '产品数量', value: 'qty' },
        { text: '产品单价', value: 'price' },
        { text: '总金额', value: 'total' },
        { text: '下单时间', value: 'created_at' },
      ],
      merchantHeaders: [
        { text: 'ID', value: 'id' },
        { text: '商户名', value: 'name' },
      ],
      icons: {
        mdiMagnify,
        mdiClose,
        mdiDelete,
        mdiArrowRightCircle,
        mdiCalendar,
        mdiPencil,
        mdiFilterMultipleOutline,
        mdiTruckCargoContainer,
        mdiChartTimeline,
      },
      dates: [],
      exportDates: [],
      options: {
        page: 1,
        pageSize: 10,
        search: '',
        order_no: '',
      },
      merchantPage: 1,
      merchantPageSize: 10,
      totalMerchants: 0,
      merchantSearch: '',
      merchantLoading: true,
      merchants: [],
      defaultSelectMerchant: [],
      salesDownloadDialog: false,
    }
  },

  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ')
    },
    exportDateRangeText() {
      return this.exportDates.join(' ~ ')
    },
    newOptions() {
      return {
        start_date: this.dates[0] || '',
        end_date: this.dates[1] || '',
        ...this.options,
      }
    },
    merchantOption() {
      const o = {
        pageSize: this.merchantPageSize,
        page: this.merchantPage,
      }
      if (this.merchantSearch) o.search = this.merchantSearch
      return o
    },
  },

  created() {
    this.getDesserts()
  },

  methods: {
    getDesserts() {
      this.loading = true
      getSaleReport(this.newOptions).then(response => {
        this.desserts = response.data.results
        this.totalDesserts = response.data.count
        this.loading = false
      })
    },
    clearDates() {
      this.dates = []
    },
    exportClearDates() {
      this.exportDates = []
    },
    async toBeShippedDownload() {
      await downloadShippedFile()
    },
    getMerchants() {
      this.merchantLoading = true
      getMerchants(this.merchantOption).then(response => {
        this.merchants = response.data.results
        this.totalMerchants = response.data.count
        this.merchantLoading = false
      })
    },
    salesDownload() {
      this.salesDownloadDialog = true
      this.getMerchants()
    },
    cancelExportSalesDialog() {
      this.defaultSelectMerchant = []
      this.exportDates = []
      this.merchantPage = 1
      this.merchantPageSize = 20
      this.merchantSearch = ''
      this.salesDownloadDialog = false
    },
    async exportSalesSubmit() {
      if (this.exportDates.length !== 2) {
        this.$toast.error('请选择时间段')
        return
      }
      if (!this.defaultSelectMerchant.length) {
        this.$toast.error('请选择要导出的商户')
        return
      }
      const op = {
        start_date: this.exportDates[0],
        end_date: this.exportDates[1],
        merchant_ids: this.defaultSelectMerchant.map(item => item.id),
      }
      await downloadSalesFile(op)
    },
  },
}
</script>
